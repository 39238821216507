import { Modal } from "@mui/material";
import "./styles/RegisterModal.css";
import closeIcon from "../../resources/images/close-icon.svg";
import userNotRegisteredImage from "../../resources/images/user-not-registered.svg";
import { useNavigate } from "react-router-dom";
const RegisterModal = (props) => {
    const navigate = useNavigate();
    const { openModal, handleClose } = props;
    return (
        <Modal open={openModal} onClose={() => handleClose()}>
            <section className="reg-now-modal-wrapper-bg">
                <div className="reg-now-modal-wrapper">
                    <img src={closeIcon} className="reg-now-modal-close" onClick={handleClose} />
                    <div className="reg-now-modal-content">
                        <div className="reg-now-user-img">
                            <img src={userNotRegisteredImage} alt="" />
                        </div>
                        <span className="reg-now-modal-text">
                            Only Community Members have access to a Referral Code. You can register
                            and join the Community to get a unique Referral Code and earn Rewards!
                        </span>
                        <div className="reg-now-btn-wrapper">
                            <button
                                onClick={() => {
                                    navigate("/");
                                }}
                            >
                                Register Now
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </Modal>
    );
};

const RedeemModal = (props) => {
    const { openModal, handleClose, activateRedeemFlow, redeemData } = props;
    return (
        <Modal open={openModal} onClose={() => handleClose()}>
            <section className="redeem-modal-wrapper-bg">
                <div className="redeem-modal-wrapper">
                    <div className="redeem-modal-content">
                        <h1 className="redeem-modal-heading">
                            Are you sure you want to redeem this reward?
                        </h1>
                        <p className="redeem-modal-text">
                            {redeemData.pointsOfReward} points will be deducted, and your reward
                            fulfilment process will begin once you click on "Yes".
                        </p>
                        <div className="redeem-btn-wrapper">
                            <button className="no-btn" onClick={handleClose}>
                                No
                            </button>
                            <button className="yes-btn" onClick={activateRedeemFlow}>
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </Modal>
    );
};
const RewardConfirmationModal = (props) => {
    const { openModal, handleClose } = props;
    return (
        <Modal open={openModal} onClose={() => handleClose()}>
            <section className="reward-modal-wrapper-bg">
                <div className="reward-modal-wrapper">
                    <div className="reward-modal-content">
                        <span className="reward-modal-text">
                            We have recieved your request, and will reach out to you within 48 hours
                            to fulfill the request.
                        </span>
                        <div className="reward-btn-wrapper">
                            <button onClick={props.handleClose}>Ok, Got it</button>
                        </div>
                    </div>
                </div>
            </section>
        </Modal>
    );
};
export { RegisterModal, RedeemModal, RewardConfirmationModal };
