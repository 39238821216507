import Trophy from "../../resources/images/trophy.svg";
import Logo from "../../resources/images/logo-ppa.svg";
import "./styles/CommunityPageSections.css";
import PuzzleEmoji from "../../resources/images/puzzle.svg";
import ShackHandEmoji from "../../resources/images/shake-hand-small.svg";
import mentorEmoji from "../../resources/images/mentored-emoji.svg";
import chatEmoji from "../../resources/images/chat-emoji.svg";
import { useNavigate } from "react-router-dom";
import { PPA_COMMUNITY_MEDIA_BUCKET_PREFIX } from "./HomeConstants";

const CommunityPageSections = () => {
    const navigate = useNavigate();
    const getWhatSection = () => {
        return (
            <div className="what-section-wrapper">
                <h1 className="what-heading">What is Code Everyday?</h1>
                <div className="what-content-wrapper">
                    <img
                        className="dev-image"
                        src={PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `developer.png`}
                        alt=""
                    />
                    <li className="what-list">
                        <ul>
                            <img src={PuzzleEmoji} /> Solve coding problems together live on Youtube
                        </ul>
                        <ul>
                            <img src={ShackHandEmoji} /> Join Community-Only Free Events and Courses
                        </ul>
                        <ul>
                            <img src={mentorEmoji} /> Get mentored and grow in your tech career
                        </ul>
                        <ul>
                            <img src={chatEmoji} /> Take part in dynamic and insightful
                            discussions with peers
                        </ul>
                    </li>
                </div>
            </div>
        );
    };
    const getWhySection = () => {
        return (
            <div className="why-section-wrapper">
                <h1 className="why-heading">Why Consistency Matters</h1>
                <div className="why-content-wrapper">
                    <img
                        className="consistency-img"
                        src={PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `consistency.png`}
                        alt=""
                    />
                    <li className="why-list">
                        <ul>1. Master your skills.</ul>
                        <ul>2. Boost problem solving speed and efficiency.</ul>
                        <ul>3. Remain up-to-date with the latest tools and knowledge.</ul>
                        <ul>4. Build confidence in your Software Engineering abilities.</ul>
                    </li>
                </div>
                <div className="why-gift-wrapper">
                    <p className="gift-text">And we help you achieve that!</p>
                    <img src={Trophy} alt="" />
                </div>
            </div>
        );
    };
    const getAboutSection = () => {
        return (
            <div className="about-section-wrapper">
                <h1 className="about-heading">About Programming Pathshala</h1>
                <div className="about-content-wrapper">
                    <img src={Logo} alt="" />
                    <p className="about-content">
                        Programming Pathshala is an organisation focused on making{" "}
                        <span className="blue-text">high quality Tech Education accessible</span>,
                        and helping anyone who wants to make it big in tech - get where they want to
                        be. With a <span className="blue-text">4.9/5</span>
                        Google rating, our <span className="blue-text">
                            IIT trained mentors
                        </span>{" "}
                        have <span className="blue-text">taught 20,000+ students</span>, and enabled
                        them to be high in demand tech professionals.
                    </p>
                </div>
            </div>
        );
    };
    const ReferAndEarn = () => {
        return (
            <div className="refer-earn-section-wrapper">
                <h1>Refer and Earn</h1>
                <div className="refer-earn-content-wrapper">
                    <img className="refer-earn-img" src={PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `refer-and-earn.png`} alt="" />
                    <p className="refer-earn-content">
                        Share your unique referral code with your friends, family, or anyone in your
                        network and win exclusive rewards and goodies.
                        <div className="refer-earn-btn-wrapper">
                            <button
                                type="refer-earn-submit"
                                onClick={() => {
                                    navigate(`/leaderboard`);
                                }}
                            >
                                Refer Now
                            </button>
                        </div>
                    </p>
                </div>
            </div>
        );
    };
    return (
        <div>
            {getWhatSection()}
            {getWhySection()}
            {ReferAndEarn()}
            {getAboutSection()}
        </div>
    );
};

export default CommunityPageSections;
