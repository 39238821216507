import React from "react";
import NavLinks from "./NavLinks";
import logo from "../../resources/images/PpaLogo.svg";
import HeaderForMob from "./HeaderForMob";
import { useState, useEffect } from "react";
import { useGlobalContext } from "../../context/GlobalContext";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import { useNavigate } from "react-router-dom";
import "./styles/Header.css";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Header = (props) => {
    const query = useQuery();
    const navigate = useNavigate();
    const [buttonName, setButtonName] = useState("Sign Up");
    const { loggedIn, initiateLogout } = useGlobalContext();

    const { windowWidth } = useWindowDimensions();
    const listenToScroll = () => {
        let height = 400;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        setButtonName(winScroll);

        if (height >= winScroll) {
            setButtonName("Sign Up");
        } else {
            setButtonName("Start 7 Day Free Trial");
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    const getHeaderforDesktop = () => {
        return (
            <div className="header-links">
                <div className="ppa-logo-container">
                    <a target="_blank" rel="noreferrer" href="https://programmingpathshala.com/">
                        <img className="ppa-header-logo" src={logo} alt="" />
                    </a>
                </div>

                <NavLinks />

                <div>{getHeaderBtnForDeskTop()}</div>
            </div>
        );
    };

    const getHeaderBtnForDeskTop = () => {
        if (!loggedIn) {
            return (
                <div className="header-buttons">
                    <div
                        onClick={() => {
                            window.open("https://renaissance.programmingpathshala.com/login");
                        }}
                    >
                        <p className="log-in-or-out-btn">Log In</p>
                    </div>
                    <div
                        onClick={() => {
                            window.open("https://renaissance.programmingpathshala.com/signup");
                        }}
                    >
                        <button className="sign-up-btn">{buttonName}</button>
                    </div>
                </div>
            );
        } else {
            return (
                <div onClick={() => initiateLogout()}>
                    <p className="log-in-or-out-btn">Log Out</p>
                </div>
            );
        }
    };

    return (
        <div className=" header-container">
            {windowWidth > 600 ? getHeaderforDesktop() : <HeaderForMob />}

            {windowWidth <= 600 ? (
                <>
                    <div className="ppa-header-logo-wrapper">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://programmingpathshala.com/"
                        >
                            <img
                                className="ppa-logo"
                                src={logo}
                                alt="ppa-logo"
                                width={220}
                                height={35}
                            />
                        </a>
                    </div>
                    <div
                        onClick={() =>
                            window.open(
                                "https://renaissance.programmingpathshala.com/signup",
                                "_self"
                            )
                        }
                    ></div>
                    <div onClick={() => (!loggedIn ? navigate("/login") : initiateLogout())}>
                        {!loggedIn ? (
                            <p className="log-in-or-out-btn">Log In</p>
                        ) : (
                            <p className="log-in-or-out-btn">Log Out</p>
                        )}
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default Header;
