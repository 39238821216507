import React from "react";
import ppaLogo from "../../resources/images/ppa-footer-logo.svg";
import "./styles/Footer.css";
import facebookIcon from "../../resources/images/footer-fb.png";
import youtubeIcon from "../../resources/images/footer-yt.png";
import linkedInIcon from "../../resources/images/footer-ln.png";
import telegramIcon from "../../resources/images/footer-tg.png";
import instagramIcon from "../../resources/images/footer-ig.png";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer-header">
                <img src={ppaLogo} alt="" />
                <h1>Programming Pathshala</h1>
            </div>
            <div className="footer-contents">
                <div className="footer-section-company-courses">
                    <div className="footer-section-company">
                        <h2>COMPANY</h2>

                        <a href="https://programmingpathshala.com/">
                            {" "}
                            <p>About Us</p>
                        </a>
                        <a href="https://renaissance.programmingpathshala.com/crack-coding-interviews/stories">
                            <p>Success Stories</p>
                        </a>

                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://blogs.programmingpathshala.com/"
                        >
                            <p>Blogs</p>
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://events.programmingpathshala.com/"
                        >
                            <p>Events</p>
                        </a>
                    </div>
                    <div className="footer-section-courses">
                        <h2>COURSES</h2>
                        <a href="https://renaissance.programmingpathshala.com/crack-coding-interviews#">
                            <p>Renaissance for Top Tech Companies</p>
                        </a>
                        <a href="https://renaissance.programmingpathshala.com/low-level-design#">
                            <p>Low Level Design and Concurrency</p>
                        </a>

                        <a href="https://renaissance.programmingpathshala.com/dsa-and-algorithms-essentials#">
                            <p>Crash Course in Data Structures & Algorithms</p>
                        </a>

                        <p>Backend Development with Java Spring Boot</p>
                        <a href="https://renaissance.programmingpathshala.com/begin-with-c++">
                            <p>Begin with Programming in C++</p>
                        </a>
                    </div>
                    <div className="footer-section-courses new">
                        <span className="line-break"></span>
                        <a href="https://renaissance.programmingpathshala.com/begin-with-python">
                            <p>Become a Python Expert</p>
                        </a>
                        <p>System Design for Professionals</p>
                        <p>Be a Data Engineer</p>
                        <p>Full Stack Development in MERN</p>
                    </div>
                </div>

                <div className="footer-section">
                    <div>
                        <h2>SERVICES</h2>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://forms.gle/nG8FArKkwNEJucqE7"
                            className="links-font-style"
                            to=""
                        >
                            <p>Get Scholarships</p>
                        </a>

                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://forms.gle/jsepbL2id7gpkSLeA"
                            className="links-font-style"
                            to=""
                        >
                            {" "}
                            <p>Teach with Us</p>
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://chat.whatsapp.com/LMuLadcPEdXC9ZUNWD2U25"
                            className="links-font-style"
                            to=""
                        >
                            {" "}
                            <p>Join our Community</p>
                        </a>
                        <div className="policies">
                            <h2>PRIVACY POLICY</h2>
                            <Link to="https://renaissance.programmingpathshala.com/terms-and-conditions">
                                <p>Terms and Conditions</p>
                            </Link>
                            <Link to="https://renaissance.programmingpathshala.com/refund-policy">
                                <p>Refund Policy</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-icons-wrapper">
                <a
                    href="https://www.facebook.com/Programmingpathshala.1"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={facebookIcon} alt="" />
                </a>
                <a
                    href="https://www.youtube.com/channel/UC4uPfwRHPbYlmjNMiKIdWNg"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={youtubeIcon} alt="" />
                </a>
                <a
                    href="https://www.linkedin.com/school/programming-pathshala/mycompany/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    <img src={linkedInIcon} alt="" />
                </a>
                <a
                    href="https://t.me/programmingpathshala"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={telegramIcon} alt="" />
                </a>
                <a
                    href="https://www.instagram.com/programmingpathshala/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={instagramIcon} alt="" />
                </a>
            </div>
            <div className="footer-line-break">
                <p>All rights reserved with @Programming Pathshala</p>
            </div>
        </div>
    );
};

export default Footer;
