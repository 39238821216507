import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { coursesDetails } from "./CourseListConstants";
import "./styles/HeaderCoursesDropDown.css";
import newSticker from "../../resources/images/NewSticker.svg";
export default function HeaderCoursesDropDown() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

   
    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                    textTransform: "none",
                    color: "#4f516b",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "1vw",
                    whiteSpace: "noWrap",
                    letterSpacing: 0,
                    "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent",
                    },
                }}
                className="header-nav-link m-0"
            >
                Explore Courses <ExpandMoreIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {coursesDetails &&
                    coursesDetails.map((data, index) => (
                        <MenuItem
                            divider={true}
                            key={index}
                            className="course-data-wrapper"
                            onClick={handleClose}
                        >
                            <a
                                href={data.clickable && data.courseLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className="course-name-wrapper">
                                    <h3 className="course-name">{data.courseName}</h3>
                                    {data.showNewIcon && (
                                        <img className="new-tag-img" src={newSticker} alt="" />
                                    )}
                                </div>
                                <p className="course-description">{data.courseDescription}</p>
                            </a>
                            <hr className="horizontal-line" />
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
}
