import groupCoding from "../../resources/images/group-coding.svg";
import "./styles/ImproveProblemSolving.css";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
const ImproveProblemSolving = () => {
    const { windowWidth } = useWindowDimensions();

    const scrollToRegForm = () => {
        if (windowWidth > 600) {
            window.scrollTo({ top: 300, left: 0, behavior: "smooth" });
        } else {
            window.scrollTo({ top: 50, left: 0, behavior: "smooth" });
        }
    };

    return (
        <div className="problem-solving-section-wrapper">
            <img src={groupCoding} alt="" />
            <div>
                <h1>
                    Want to improve your Problem Solving? <br />{" "}
                    <span className="blue-text">Code Everyday</span> with us.{" "}
                </h1>
                <div className="join-community-button-wrapper">
                    <button onClick={scrollToRegForm}>Join our Community</button>
                </div>
            </div>
        </div>
    );
};

export default ImproveProblemSolving;
