import React from "react";
import "./LinearGradientBtn.css";

const LinearGradientBtn = (props) => {
    return (
        <div className="linear-gradient-btn-container">
            <button className="linear-gradient-btn" data={props.name}></button>
        </div>
    );
};

export default LinearGradientBtn;
