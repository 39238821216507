import percntageImg from "../../resources/images/SharePercentageIcon.svg";
import TshirtIllustrator from "../../resources/images/TshirtIllustrator.svg";
import bagIllustrator from "../../resources/images/bagIllustrator.svg";
import keyBoardIllustrator from "../../resources/images/keyBoardIllustrator.svg";
import earphonesIllustrator from "../../resources/images/earphonesIllustrator.svg";
import extendSubscription from "../../resources/images/PPASubscription.svg";

const giftCardContents = [
    {
        image: percntageImg,
        description: "20% of on any course",
        coinsEarned: 1000,
        formLink:
            " https://docs.google.com/forms/d/1EuwV7dLXGC-JhS0JKhWsfXMEVV9x7IWQJk9ciPVmDbU/edit",
    },
    {
        image: TshirtIllustrator,
        description: `"Code Till Infinity" T-shirt`,
        coinsEarned: 5000,
        formLink:
            "https://docs.google.com/forms/d/1sKlJhjdXjhTzD9CZ7fgkt1O0_le-xdtEvHrkFX3I_ns/edit",
    },
    {
        image: bagIllustrator,
        description: "Exclusive Laptop Bag",
        coinsEarned: 15000,
        formLink:
            "https://docs.google.com/forms/d/1isCkFlxOG-RTaKgylCInjAVhAYXCkP6mdfQ9P-PxZ9E/edit",
    },
    {
        image: keyBoardIllustrator,
        description: "Logitech Keyboard",
        coinsEarned: 30000,
        formLink:
            "https://docs.google.com/forms/d/1Dg1LhCXgdD_Zt9D0AH-_o_2qXaOYWBa1EparzSNHnfo/edit",
    },
    {
        image: earphonesIllustrator,
        description: "boAt Earphones",
        coinsEarned: 40000,
        formLink:
            "https://docs.google.com/forms/d/1Nqzd7Z-rg0ISKB7sZWRMCsMIVg-RS7OKOh-MHKwuyww/edit",
    },
    {
        image: extendSubscription,
        description: "Free 1 Month Subscription",
        coinsEarned: 50000,
        formLink:
            "https://docs.google.com/forms/d/18rTgvhnutzje9dPg2T5pzrD4M0m5b8KNM-HLnTeSZMc/edit",
    },
];
const LEADERBOARD_ROW_LIMIT = 10;
const LEADERBOARD_ROW_OFFSET = 0;
export { giftCardContents, LEADERBOARD_ROW_LIMIT, LEADERBOARD_ROW_OFFSET };
