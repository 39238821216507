import React from "react";
import { howDoesItWorkCardData } from "./HowDoesItWorkConstants";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import stepper from "../../resources/images/stepper.png";
import "./styles/HowDoesItWork.css";

const HowDoesItWork = () => {
    const { windowWidth } = useWindowDimensions();

    const gettheStepsAndCards = () => {
        return (
            <div className="steps-and-cards-wrapper">
                {windowWidth > 600 && (
                    <div className="stepper-image-wrapper">
                        <img src={stepper} alt="" />
                    </div>
                )}

                <div>
                    {howDoesItWorkCardData &&
                        howDoesItWorkCardData.map((data, index) => (
                            <div className="steps-card-and-content-wrapper">
                                <div>
                                    <img src={data.image} alt="" />
                                </div>
                                <div>{data.description}</div>
                            </div>
                        ))}
                </div>
            </div>
        );
    };

    return (
        <div className="how-does-it-work-wrapper">
            <h3 className="how-does-it-work-heading">How Does It Work?</h3>
            <div>{gettheStepsAndCards()}</div>
        </div>
    );
};

export default HowDoesItWork;
