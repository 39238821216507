import SendCodeIllustrator from "../../resources/images/SendCodeIllustrator.svg";
import useCouponCodellustrator from "../../resources/images/useCouponCodellustrator.svg";
import getCouponIllustrator from "../../resources/images/getCouponIllustrator.svg";
import coinsIllustrator from "../../resources/images/coinsIllustrator.svg";
import giftBox from "../../resources/images/giftBox.svg";

const howDoesItWorkCardData = [
    {
        image: SendCodeIllustrator,
        description: (
            <p className="step-card-description">
                Share your Unique <strong>“referral code/ message” </strong> with your friend👫
            </p>
        ),
    },
    {
        image: useCouponCodellustrator,
        description: (
            <p className="step-card-description">
                Your friend uses your Unique <strong> “Referral Code” </strong>and <br /> registers
                for the Community.
            </p>
        ),
    },
    {
        image: getCouponIllustrator,
        description: (
            <p className="step-card-description">
                You win Points <img className="steps-coin-image" src={coinsIllustrator} alt="" />
                which can be redeemed to <br /> <strong> Win Exciting Gifts! </strong>
                <img className="steps-gift-box" src={giftBox} alt="" />
            </p>
        ),
    },
];

export { howDoesItWorkCardData };
