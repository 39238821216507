import "./styles/LeaderBoard.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import RewardsSection from "./RewardsSection";
import HowDoesItWork from "./HowDoesItWork";
import ShowLeaderboard from "./ShowLeaderboard";
import ShowReferralCodeSection from "./ShowReferralCodeSection";
import SpringBanner from "../banner/SpringBanner";

const LeaderBoard = () => {
    return (
        <div>
            <div>
                <SpringBanner />
            </div>
            <div className="header-sticky">
                <Header />
            </div>
            <div className="leaderboard-page-container">
                <ShowReferralCodeSection />
                <RewardsSection />
                <ShowLeaderboard />
                <HowDoesItWork />
            </div>

            <Footer />
        </div>
    );
};

export default LeaderBoard;
