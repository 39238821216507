const validate = (values) => {
    const errors = {};
    const phoneInvalid = isPhoneNumberInvalid(values.phone);
    const emailInvalid = isEmailInvalid(values.email);
    if (!values.name) {
        errors.name = "Name is required!";
    }
    if (phoneInvalid) {
        errors.phone = phoneInvalid;
    }
    if (emailInvalid) {
        errors.email = emailInvalid;
    }
    return errors;
};
const isPhoneNumberInvalid = (value) => {
    const PHONE_REGEX = /^[0-9]\d*$/;
    if (!value) {
        return "Phone number is required!";
    } else return false;
};

const isEmailInvalid = (value) => {
    const EMAIL_REGEX =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!value) {
        return "email is required!";
    } else if (!EMAIL_REGEX.test(value)) {
        return "Error! Please enter valid email.";
    } else return false;
};
export { isPhoneNumberInvalid, validate, isEmailInvalid };
