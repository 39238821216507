import React from "react";
import { Modal } from "@mui/material";
import shackHandImg from "../../resources/images/shake-hand.svg";
import Vectorclose from "../../resources/images/Vectorclose.svg";
import "./styles/Intro.css";

const CommunityFromSubmitModal = (props) => {
    const { openModal, handleClose } = props;
    return (
        <div className="community-submitted-modal-wrapper">
            <Modal
                open={openModal}
                onClose={handleClose}
            >
                <div className="community-submit-modal">
                    <div className="community-submit-modal-greeting">Thanks for registering for the community!</div>
                    <img
                        src={shackHandImg}
                        className="community-submit-modal-success"
                        onClick={props && props.handleClose}
                    />
                    <img
                        src={Vectorclose}
                        className="community-submit-modal-close"
                        onClick={props.handleClose}
                    />

                    <p className="community-submit-modal-info">
                        You will receive the Community joining link via your <span>Email</span> and <span>WhatsApp</span> shortly.
                    </p>
                </div>
            </Modal>
        </div>
    );
};

export default CommunityFromSubmitModal;
