import React, { useState } from "react";
import "./styles/ShowReferralCodeSection.css";
import PhoneInput from "react-phone-input-2";
import AutoCopyImg from "../../resources/images/auto-copy-img.svg";
import { getCommunityUserPoints } from "./LeaderBoardClient";
import { CircularProgress } from "@mui/material";
import { RegisterModal } from "./LeaderBoardModals";
import { useLeaderBoardContext } from "../../context/LeaderBoardContext";
import coinImage from "../../resources/images/coinsIllustrator.svg";

const ShowReferralCodeSection = () => {
    const [validationError, setValidationError] = useState("");

    const [registerModal, setRegisterModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [copy, setCopy] = useState(false);

    const {
        userPoints,
        setUserPoints,
        referralCode,
        setReferralCode,
        phoneNumber,
        setPhoneNumber,
        setUserRankInfo
    } = useLeaderBoardContext();

    const handlePhoneChange = (e) => {
        setPhoneNumber(e);
    };

    const handleSubmit = () => {
        if (phoneNumber) {
            setValidationError("");
            let data = {
                phone: phoneNumber,
            };
            setLoader(true);
            setUserPoints(null);
            getCommunityUserPoints(
                data,
                setUserPoints,
                setUserRankInfo,
                setReferralCode,
                setRegisterModal,
                setLoader
            );
        } else {
            setValidationError("Invalid Phone Number");
        }
    };

    const handleClose = () => {
        if (registerModal) {
            setRegisterModal(false);
        }
    };
    const getCopyLink = () => {
        if (referralCode) {
            setCopy(!copy);
            const referralCodeLink = `https://community-staging.programmingpathshala.com?referral_code=${referralCode}`;
            navigator.clipboard.writeText(referralCodeLink);
        }
    };
    return (
        <div className="show-referral-code-wrapper">
            <h1>Rewards and Leaderboard</h1>
            <p className="show-referral-code-wrapper-desc">
                Enter Phone Number and check your Points
            </p>
            <div className="show-referral-code-phone-input">
                <PhoneInput
                    placeholder="WhatsApp Number"
                    inputStyle={{
                        height: "46px",
                        background: "var(--dark-mode-800, #20232a)",
                        width: "100%",
                        fontSize: "1.1vw auto",
                        color: "#F7F8F9",
                        display: "block",
                    }}
                    country={"in"}
                    value={phoneNumber}
                    onChange={(phone) => handlePhoneChange(phone)}
                />
                <button className="referral-code-check-btn" onClick={handleSubmit}>
                    Check
                </button>
            </div>
            {validationError && <span className="error-phone-number">{validationError}</span>}
            {loader && (
                <div className="referral-code-loader">
                    <CircularProgress size={30} />
                </div>
            )}
            {registerModal && <RegisterModal handleClose={handleClose} openModal={registerModal} />}
            <div className="show-points-and-referral-code">
                <div className="show-points-section">
                    <div className="your-points">Your Points</div>
                    <div className="total-points-box">
                        <img src={coinImage} alt="" />
                        {userPoints >= 0 ? (
                            <p className="user-points-text">{userPoints}</p>
                        ) : (
                            <div className="blank-line" />
                        )}
                        <p className="total-points-txt">Total Points</p>
                    </div>
                </div>
                <div className="show-referral-code-section">
                    <div className="your-code">Your Referral Code: </div>
                    <div className="referral-code-box" onClick={getCopyLink}>
                        <img src={AutoCopyImg} />
                        {referralCode ? referralCode : " Copy Sharable Message"}
                    </div>
                </div>
            </div>
            {referralCode && copy && <p className="copy-msg">Copied Successfully</p>}
            <div className="show-referral-code-content-wrapper"></div>
        </div>
    );
};

export default ShowReferralCodeSection;
