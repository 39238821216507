import axios from "axios";
import { LEADERBOARD_ROW_LIMIT, LEADERBOARD_ROW_OFFSET } from "./EarnCoinsConstants";

const getCommunityUserPoints = (
    data,
    setPoints,
    setUserRankInfo,
    setReferralCode,
    setRegisterModal,
    setLoader
) => {
    axios
        .post(process.env.REACT_APP_MISCELLANEOUS_URL + "/getCommunityUserPoints", data)
        .then((response) => {
            setLoader(false);
            if (response && response.data) {
                setReferralCode(response.data.referralCode);
                setPoints(response.data.userPoints);
                setUserRankInfo(response.data.userRankInfo);
            }
        })
        .catch((error) => {
            setLoader(false);
            if (
                error &&
                error.response &&
                error.response.data &&
                !error.response.data.userRegistered
            ) {
                setRegisterModal(true);
            }
            console.log(error);
        });
};

const getCommunityLeaderBoard = (setLeaderBoard) => {
    axios
        .get(
            process.env.REACT_APP_MISCELLANEOUS_URL +
                `/getCommunityLeaderBoard?limit=${LEADERBOARD_ROW_LIMIT}&offset=${LEADERBOARD_ROW_OFFSET}`
        )
        .then((response) => {
            if (response && response.data) {
                setLeaderBoard(response.data.leaderBoardDetails);
            }
        })
        .catch((error) => {
            console.log(error);
        });
};
const getReward = (data, setUserPoints, setRewardConfirmationModal, setLoader) => {
    axios
        .post(process.env.REACT_APP_MISCELLANEOUS_URL + "/getRewardAndDeductPoints", data)
        .then((response) => {
            setLoader(false);
            if (response && response.status === 200) {
                console.log("inside confirmation modal setting open ");
                if (response.data) {
                    setUserPoints(response.data.updatedUserPoints);
                }
                setRewardConfirmationModal(true);
            }
        })
        .catch((error) => {
            setLoader(false);
            console.log(error);
        });
};
export { getCommunityUserPoints, getCommunityLeaderBoard, getReward };
