import React from "react";
import "./styles/SpringBanner.css";
import springIcon from "../../resources/images/springIcon.svg";

const SpringBanner = () => {
    
    return (
        <div className="spring-banner">
            <img src={springIcon} alt=""/>
            <p>Learn Backend Engineering in JAVA Spring Framework &nbsp;|&nbsp; Get ₹4000 OFF Today!</p>
            <a href="https://events.programmingpathshala.com/Events/76" target="_blank"
            rel="noreferrer"
            >
                <button>Know More</button>
            </a>
        </div>
    );
};

export default SpringBanner;
