import cpp from "../../resources/images/cpp.svg";
import python from "../../resources/images/python.svg";
import lld from "../../resources/images/lld.svg";
import nodejs from "../../resources/images/node-js.svg";
import java from "../../resources/images/java.svg";
import renaissance from "../../resources/images/renaissance.svg";

const PPA_COMMUNITY_MEDIA_BUCKET_PREFIX =
    "https://storage.googleapis.com/ppa-prod-community-media/";
const COURSE_DETAILS = [
    {
        courseBannerImg: renaissance,
        courseDescription:
            "Master Data Structures & Algorithms and System Design and become 100% Coding ready and Crack Top-notch Tech roles.",
        courseLink: `https://renaissance.programmingpathshala.com/crack-coding-interviews`,
    },
    {
        courseBannerImg: lld,
        courseDescription:
            "Crack Design and Machine Coding rounds for Senior Software Engineer Roles",
        courseLink: `https://renaissance.programmingpathshala.com/low-level-design`,
    },
    {
        courseBannerImg: nodejs,
        courseDescription:
            "NodeJS event - Learn building, testing and deploying scalable Microservices using NestJS/NodeJS, Docker & Kubernetes, Databases, Cloud & more.",
        courseLink: `https://events.programmingpathshala.com/Events/77`,
    },
    {
        courseBannerImg: java,
        courseDescription:
            "Master developing stable & scalable backends using Spring Boot, Hibernate,JPA,and more.",
        courseLink: `https://events.programmingpathshala.com/Events/76`,
    },
    // {
    //     courseBannerImg: cpp,
    //     courseDescription:
    //         "A very concise program with curated and handpicked concepts, for you to master DSA quickly and crack your dream job.",
    //     courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}dsa-and-algorithms-essentials`,
    // },
    {
        courseBannerImg: cpp,
        courseDescription:
            "A practical and hands-on course for those who are eager to master C++. Beginner-friendly.",
        courseLink: `https://renaissance.programmingpathshala.com/begin-with-c++`,
    },

    {
        courseBannerImg: python,
        courseDescription:
            "Become a Python pro and unlock the door to endless career opportunities in Data Science and Software Development.",
        courseLink: `https://renaissance.programmingpathshala.com/begin-with-python`,
    },
];

const YOUTUBE_VIDEO_DETAILS = [
    {
        videoLink: "https://www.youtube.com/watch?v=t-yAYctAmf4",
        videoBanner: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `competetive-programming.webp`,
    },
    {
        videoLink: "https://www.youtube.com/watch?v=vu9xfJeqmIA",
        videoBanner: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `decode-string.webp`,
    },
    {
        videoLink: "https://www.youtube.com/watch?v=QxAEygMGPe4",
        videoBanner: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `zeal-wagadia.webp`,
    },
    {
        videoLink: "https://www.youtube.com/watch?v=0dP3nFlsXsY",
        videoBanner: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `abhishika.webp`,
    },
    {
        videoLink: "https://www.youtube.com/watch?v=Je4AuyryI5I",
        videoBanner: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `shreyash-git.webp`,
    },
    {
        videoLink: "https://www.youtube.com/watch?v=8rmngl9sqrM&t=1s",
        videoBanner: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `vivek-yt.webp`,
    },
    {
        videoLink: "https://www.youtube.com/watch?v=5z0L6m1QKNg",
        videoBanner: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `referrals-yt.webp`,
    },
];

const LIST_OF_IMAGES = {
    LEFT_IMAGE: {
        UPPER_IMAGE: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `ruby.svg`,
        MIDDLE_IMAGE: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `lpu-students.svg`,
        LOWER_IMAGE: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `vivek.svg`,
    },
    SECOND_LEFT_IMAGE: {
        UPPER_IMAGE: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `bharat-teaching.svg`,
        MIDDLE_IMAGE: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `students-coding.svg`,
        LOWER_IMAGE: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `github.svg`,
    },
    SECOND_RIGHT_IMAGE: {
        UPPER_IMAGE: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `bharat-writing.svg`,
        MIDDLE_IMAGE: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `chitkara-students.svg`,
        LOWER_IMAGE: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `python-logo.svg`,
    },
    RIGHT_IMAGE: {
        UPPER_IMAGE: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `angular.svg`,
        MIDDLE_IMAGE: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `hackathon.svg`,
        LOWER_IMAGE: PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `iit-bhu-students.svg`,
    },
};
const SMALL_IMG_CLASSNAME = "column-img-small";
const IMG_CLASSNAME = "column-img";

export {
    LIST_OF_IMAGES,
    SMALL_IMG_CLASSNAME,
    IMG_CLASSNAME,
    COURSE_DETAILS,
    YOUTUBE_VIDEO_DETAILS,
    PPA_COMMUNITY_MEDIA_BUCKET_PREFIX,
};
