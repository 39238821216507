import "./App.css";
import "./Fonts.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import LeaderBoard from "./components/LeaderBoard/LeaderBoard";
import { GlobalContextProvider } from "./context/GlobalContext";
import { LeaderBoardContextProvider } from "./context/LeaderBoardContext";

function App() {
    return (
        <div className="App">
            <GlobalContextProvider>
                <Router>
                    <Routes>
                        <Route exact path="" element={<Home />} />
                        <Route
                            exact
                            path="/leaderboard"
                            element={
                                <LeaderBoardContextProvider>
                                    <LeaderBoard />{" "}
                                </LeaderBoardContextProvider>
                            }
                        />
                    </Routes>
                </Router>
            </GlobalContextProvider>
        </div>
    );
}

export default App;
