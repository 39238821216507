import React, { useContext, useState } from "react";

const LeaderBoardContext = React.createContext();

export function useLeaderBoardContext() {
    return useContext(LeaderBoardContext);
}

export function LeaderBoardContextProvider({ children }) {
    const [userPoints, setUserPoints] = useState(null);
    const [userRankInfo, setUserRankInfo] = useState(null);
    const [referralCode, setReferralCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState();

    const value = {
        userPoints,
        setUserPoints,
        referralCode,
        setReferralCode,
        phoneNumber,
        setPhoneNumber,
        userRankInfo,
        setUserRankInfo,
    };
    return <LeaderBoardContext.Provider value={value}>{children}</LeaderBoardContext.Provider>;
}
