import React from "react";
import giftBox from "../../resources/images/bigGiftBox.svg";
import "./styles/ShowLeaderboard.css";
import { useEffect, useState } from "react";
import { getCommunityLeaderBoard } from "./LeaderBoardClient";
import coinsIllustrator from "../../resources/images/coinsIllustrator.svg";
import { useLeaderBoardContext } from "../../context/LeaderBoardContext";
import useWindowDimensions from "../../hooks/UseWindowDimensions";

const ShowLeaderboard = () => {
    const { windowWidth } = useWindowDimensions();
    const [leaderBoard, setLeaderBoard] = useState([]);
    const { phoneNumber, userPoints, userRankInfo } = useLeaderBoardContext();
    useEffect(() => {
        getCommunityLeaderBoard(setLeaderBoard);
    }, []);

    const getRow = (item) => {
        if (parseInt(item.points) > 0) {
            return (
                <tr key={item.rank}>
                    <td>{item.rank}</td>
                    <td className="user-name">{item.name}</td>
                    <td className="user-points">
                        {" "}
                        <img src={coinsIllustrator} alt="" />
                        {item.points}
                    </td>
                </tr>
            );
        }
    };
    const getCurrentUserRow = () => {
        if (userRankInfo && userRankInfo[0]) {
            return (
                <tr className="blue-row">
                    <td>{userRankInfo[0].rank}</td>
                    <td className="user-name">{userRankInfo[0].name}</td>
                    <td className="user-points">
                        <img src={coinsIllustrator} alt="" />
                        {userRankInfo[0].points}
                    </td>
                </tr>
            );
        }
    };
    const getLeaderBoardTable = () => {
        return (
            <div className="show-leaderboard-wrapper">
                <h1>Leaderboard</h1>
                <p>1 Referral= 100 Points</p>
                <div className="show-leaderboard-content-wrapper">
                    {windowWidth > 600 && (
                        <div className="leaderboard-left-gift">
                            <img src={giftBox} />
                        </div>
                    )}

                    <div className="leaderboard-table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th className="user-name">Name</th>
                                    <th className="user-points">Total Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getCurrentUserRow()}
                                {leaderBoard.map((item, index) => getRow(item))}
                            </tbody>
                        </table>
                    </div>
                    {windowWidth > 600 && (
                        <div className="leaderboard-right-gift">
                            <img src={giftBox} />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return <>{leaderBoard ? getLeaderBoardTable() : <></>}</>;
};

export default ShowLeaderboard;
