import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Home/styles/ExploreCourses.css";
import arrowPrev from "../../resources/images/left-arrow-blue.svg";
import arrowNext from "../../resources/images/right-arrow-blue.svg";
function NextArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowNext} alt="next" />;
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />;
}

const SliderComponent = (props) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: props.slides,
        arrows: true,
        slidesToScroll: 1,
        customPaging: function (i) {
            return <a />;
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };

    return (
        <Slider {...sliderSettings}>
            {props.cardDetails &&
                props.cardDetails.map((data, index) => props.cardComponent(data, index))}
        </Slider>
    );
};

export default SliderComponent;
